import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '1349667d-9ca0-45d9-a38c-b2a088c39fc8',
  header: 'Snowchat',
  subheader: 'Snakk SNOMED her!',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    header: 'bg-blue-900',
    headerText: 'text-white',
    bubbles: 'bg-green-600'
  },
  // chatServer: 'http://localhost:5002/api/v1/chatbots/snowchat'
  chatServer: 'https://api.semanticpulse.no/api/v1/chatbots/snowchat'
  // chatServer: 'https://jellyfish-app-crv9o.ondigitalocean.app'
}
export default config
