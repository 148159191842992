import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import { useMsal } from '@azure/msal-react'
import { apiSearchScopeAction } from './authConfig'

import UserIcon from '@mui/icons-material/AccountCircle'
import useCustomerConfig from './hooks/useCustomerConfig'
import { Check, CopyAll, ThumbUp } from '@mui/icons-material'
import Markdown from 'react-markdown'
import AddCommentIcon from '@mui/icons-material/AddComment'

const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="flex items-center gap-2 flex-col p-4" tabIndex={0} role={'button'} onClick={handleLogin}>
      <UserIcon style={{ fontSize: 40 }} />
      {accounts && accounts.length ? <span>{accounts[0].name}</span> : <span>Logg in</span>}
    </div>
  )
}

const createNewChatbotThread = async (bearerToken: string, chatServer: string) => {
  const response = await fetch(chatServer + '/threads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken
    }
  })
  return (await response.json()).id as string
}

const postChatMsgToThreadAndStreamResponse = async function* (bearerToken: string, chatServer: string, threadId: string, messageText: string) {
  const newChatResponse = await fetch(chatServer + '/threads/' + threadId + '/messages', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken
    },
    body: JSON.stringify({
      message: messageText
    })
  })

  // It responds as a stream:
  const reader = newChatResponse.body?.getReader()
  let result = await reader?.read()
  while (!result?.done) {
    const text = new TextDecoder().decode(result?.value)
    const jsonObjects = text.trim().split('\n').filter(Boolean)
    for (const jsonObject of jsonObjects) {
      yield JSON.parse(jsonObject)
    }
    result = await reader?.read()
  }
}
const SnowchatHeaderWithLogo = () => {
  const customerConfig = useCustomerConfig()

  return (
    <header
      className={`${customerConfig.theme.header} ${customerConfig.theme.headerText} border-b-2`}>
      <div className={`m-auto max-w-5xl flex items-center gap-4 justify-between`}>
        <img src={customerConfig.logo} className="h-14 ml-2" alt="logo" />
        <div className="hidden md:block">
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-5xl font-bold m-0">
              {customerConfig.header}
            </h1>
            <span>{customerConfig.subheader}</span>
          </div>
        </div>
        <UserIconAndName />
      </div>
    </header>
  )
}

type Message = {
  role: 'assistant' | 'user' | 'system',
  text: string
  documents?: any[]
}

const FancySpinner = () => (
  <div className={'animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900'}></div>
)

const getAccessTokenForSemanticApi = async (accounts: any, instance: any) => {
  const accessTokenRequest = {
    scopes: [apiSearchScopeAction],
    account: accounts[0]
  }
  try {
    const accessTokenResponse =
      await instance.acquireTokenSilent(accessTokenRequest)
    return accessTokenResponse.accessToken
  } catch (error) {
    console.log('Silent token acquisition fails. Acquiring token using popup')
    try {
      const accessTokenResponse =
        await instance.acquireTokenRedirect(accessTokenRequest)
      return accessTokenResponse.accessToken
    } catch (err) {
      console.error(err)
      return null
    }
  }
}
type HistoricConversationViewerProps = {
  getToken: () => Promise<string>
}
const HistoricConversationViewer = (props: HistoricConversationViewerProps) => {
  const customerConfig = useCustomerConfig()
  const [feedbacks, setFeedbacks] = useState<any[]>([])
  const [feedback, setFeedback] = useState<any>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const loadHistoricConversation = async (conversationId: string) => {
    const token = await props.getToken()
    const response = await fetch(customerConfig.chatServer + '/feedback/' + conversationId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const feedback = await response.json()
    setFeedback(feedback)
  }

  const fetchHistoricConversations = async () => {
    const token = await props.getToken()
    if (!token) {
      console.error('Failed to get access token')
      return
    }
    const response = await fetch(customerConfig.chatServer + '/feedback', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    if (response.ok) {
      const conversations = await response.json()
      setFeedbacks(conversations)
    }
  }

  useEffect(() => {
    if (feedbacks.length === 0) {
      fetchHistoricConversations()
    }
  }, [])

  return <div>{feedbacks.length > 0 && (<div>
    <button className="bg-gray-100 text-black p-2 rounded-lg border-2 border-dashed hover:bg-gray-200"
            onClick={() => setDialogOpen(true)}>
      Open feedback explorer
    </button>
    <div role="dialog"
         className={'flex gap-4 mt-8 mb-8 shadow-2xl fixed inset-0 p-4 bg-white rounded-lg max-w-6xl w-full z-50 m-auto ' + (dialogOpen ? '' : 'hidden')}>
      <div className="gap-1 flex flex-col overflow-y-scroll border-2 border-gray-300 rounded-lg p-2 max-w-96">
        <div>
          <button onClick={() => setDialogOpen(false)} className="bg-blue-800 text-white p-2 rounded-lg">
            Close
          </button>
        </div>
        {feedbacks.map((conv, index) => (
          <div role={'button'} key={index} onClick={() => loadHistoricConversation(conv.id)}
               className={'p-2 rounded-lg bg-gray-100 hover:bg-gray-300'}>
            <div className={'text-gray-600 flex justify-between'}>
              <span>{new Date(conv.addedAt).toLocaleString('nb-NO')}</span>
              <span>{conv.reportedBy || ' '} <span
                className={'inline-block w-6 h-6 rounded-full bg-blue-500 text-white text-center leading-6 font-bold'}>{conv.grade}</span></span>
            </div>
            <p className={''}>{conv.comment}</p>
          </div>
        ))}
      </div>
      {feedback && <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col gap-2">
          {feedback.conversation.map((message: Message, index: number) => (
            <ChatBubble key={index} color={message.role === 'assistant' ? 'fancy' : 'neutral'}>
              <Markdown>{message.text}</Markdown>
              {message.role === 'assistant' && <div className={'mt-4 p-2 flex gap-2'}>
                <CopyResponseButton text={message.text} />
              </div>}
            </ChatBubble>
          ))}
        </div>
      </div>}
    </div>
  </div>)}</div>
}

const App = () => {
  const { instance, accounts } = useMsal()
  const customerConfig = useCustomerConfig()
  const [threadId, setThreadId] = useState<string | null>(null)
  const [conversation, setConversation] = useState<Message[]>([])
  const [botIsTyping, setBotIsTyping] = useState(false)

  const startNewConversationThread = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newThreadId = await createNewChatbotThread(token, customerConfig.chatServer)
    setThreadId(newThreadId)
    setConversation([])
  }

  useEffect(() => {
    if (accounts.length > 0 && instance && threadId === null) {
      const timer = setTimeout(startNewConversationThread, 250)
      return () => clearTimeout(timer)
    }
  }, [accounts, instance, threadId])

  const onFeedbackSubmit = async (grade: number, comment: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else {
      const res = await fetch(customerConfig.chatServer + '/feedback', {
        method: 'POST',
        body: JSON.stringify({ grade, comment, conversation }),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      })
    }
  }

  const onSubmitChatMessage = async (messageText: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newConversation = [
      ...conversation,
      { role: 'user', text: messageText } as Message
    ]
    setConversation(newConversation)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else if (!threadId) {
      console.error('No thread id')
      return
    } else {
      const responseStream = postChatMsgToThreadAndStreamResponse(token, customerConfig.chatServer, threadId, messageText)
      setBotIsTyping(true)
      for await (const chunk of responseStream) {
        if (chunk.answer) {
          setConversation((conversation) => {
            const last = conversation[conversation.length - 1]
            if (last.role === 'assistant') {
              return [...conversation.slice(0, -1), { ...last, text: last.text + chunk.answer }]
            } else {
              return [...conversation, { role: 'assistant', text: chunk.answer }]
            }
          })
        }
        if (chunk.document) {
          setConversation((conversation) => {
            const last = conversation[conversation.length - 1]
            if (last.role === 'assistant') {
              return [...conversation.slice(0, -1), { ...last, documents: [...last.documents || [], chunk.document] }]
            } else {
              return [...conversation, { role: 'assistant', text: '', documents: [chunk.document] }]
            }
          })
        }
      }
      setBotIsTyping(false)
    }
  }
  const waitingForBotToRespond = conversation.length > 0 && conversation[conversation.length - 1].role === 'user'
  const conversationEmpty = conversation.length === 0

  return (
    <div className="h-screen flex flex-col">
      <SnowchatHeaderWithLogo />
      {accounts && accounts.length > 0 &&
        <HistoricConversationViewer getToken={() => getAccessTokenForSemanticApi(accounts, instance)}
        />}
      <div
        className={'overflow-y-auto flex-1 bg-gray-50 flex flex-col items-center ' + (conversationEmpty ? 'justify-center' : '')}>
        <div className="p-4 flex flex-col gap-4 lg:max-w-5xl w-full">
          {conversation.map((message, index) => (
            <div key={index} className={'flex flex-col gap-2'}>
              <ChatBubble key={index} color={message.role === 'assistant' ? 'fancy' : 'neutral'}>
                <Markdown>{message.text}</Markdown>
                {message.role === 'assistant' && <div className={'mt-4 p-2 flex gap-2'}>
                  <CopyResponseButton text={message.text} />
                </div>}
              </ChatBubble>
              {message.documents &&
                <CollapsableCard title={<h3 className={'font-bold uppercase text-gray-600'}>Kilder</h3>}>
                  <div className={'grid grid-cols-2 gap-2'}>
                    {message.documents.map((doc, index) => (
                      <div key={index}>
                        <a href={doc.source} target="_blank" rel="noreferrer"
                           className={'text-blue-800'}>{doc.source}</a>
                        <Markdown>{doc.content}</Markdown>
                      </div>
                    ))}
                  </div>
                </CollapsableCard>}
            </div>
          ))}

          {waitingForBotToRespond &&
            <ChatBubble color={'fancy'}>
              <div className={'flex gap-3 items-center'}>
                <FancySpinner />
                <div>Assistenten tenker...</div>
              </div>
            </ChatBubble>}

          {conversationEmpty && <div className={'flex-1 flex flex-col items-center text-xl text-gray-500'}>
            <p className={''}>
              <AddCommentIcon fontSize={'large'} /> Velkommen til assistenten! Still spørsmål i boksen nederst for å
              starte en samtale.
            </p>
          </div>}

          {!conversationEmpty && <div className={'flex flex-col items-center gap-4'}>
            <button
              className={'flex gap-2 bg-gray-100 text-black p-2 rounded-lg border-2 border-dashed hover:bg-gray-200'}
              onClick={startNewConversationThread}>
              <AddCommentIcon />
              Ny samtale
            </button>
            <GiveFeedback onSubmit={onFeedbackSubmit} />
          </div>
          }
        </div>
      </div>
      <div className="p-4 bg-gray-50 shadow-top">
        <ChatInputBoxAndSubmitButton onSubmit={onSubmitChatMessage}
                                     canSubmit={!waitingForBotToRespond && !botIsTyping && threadId !== null} />
      </div>
    </div>
  )
}

const CollapsableCard = (props: { title: React.ReactNode, children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="p-2 m-2 bg-gray-100 rounded-lg border-2 border-dashed">
      <div className="flex justify-between items-center">
        {props.title}
        <button onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Mindre' : 'Mer'}</button>
      </div>
      <div
        className={' ' + (isOpen ? 'max-h-full' : 'max-h-16 overflow-hidden cursor-pointer text-gray-600')}
        onClick={() => setIsOpen(true)}>
        {props.children}
      </div>
    </div>
  )
}

const CopyResponseButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyResponse = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    })
  }

  return (
    <button onClick={copyResponse}
            className={`flex items-center gap-2 ${isCopied ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'} p-2 rounded-lg border-2 ${isCopied ? '' : 'border-dashed hover:bg-gray-200'}`}>
      {isCopied ? 'Kopiert!' : 'Kopier svar'}
      {isCopied ? <Check /> : <CopyAll />}
    </button>
  )
}

type FeedbackProps = {
  onSubmit: (grade: number, comment: string) => Promise<void>
}
const GiveFeedback = (props: FeedbackProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [grade, setGrade] = useState<number | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (dialogOpen) {
      dialogRef.current?.showModal()
    } else {
      dialogRef.current?.close()
    }
  })

  const openDialog = () => {
    setDialogOpen(true)
  }
  const close = () => {
    setDialogOpen(false)
  }
  const submitFeedback = async () => {
    setIsSubmitting(true)
    await props.onSubmit(grade || 0, comment)
    setIsSubmitting(false)
    close()
  }
  return (
    <>
      <dialog
        ref={dialogRef}
        className={'fixed inset-0 p-4 bg-white shadow-lg rounded-lg max-w-3xl w-full z-50 m-auto ' + (dialogOpen ? '' : 'hidden')}>
        <div className={''}>
          <p>Hvor fornøyd var du med svaret? (1 = dårligst, 6 = best)</p>
          <div className="flex gap-2">
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <button key={n} onClick={() => setGrade(n)}
                      className={`p-4 rounded-b-full ${grade === n ? 'bg-blue-900 text-white' : 'bg-gray-100'}`}>
                {n}
              </button>
            ))}
          </div>

          <p className={'mt-6'}>Kommentar</p>
          <div>
            <textarea
              className="border-2 border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md focus:bg-white focus:text-black w-full"
              rows={7}
              value={comment}
              placeholder="Skriv en kommentar her..."
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className={'flex justify-between mt-6'}>
            <button onClick={() => close()} className="p-2 rounded-lg bg-gray-200 text-black">
              Avbryt
            </button>
            <button onClick={() => submitFeedback()}
                    className={'p-2 rounded-lg bg-blue-900 text-white ' + (isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-800')}>
              Send
            </button>
          </div>
        </div>
      </dialog>
      <button onClick={openDialog}
              className="flex items-center gap-2 bg-gray-100 text-black p-2 rounded-lg border-2 border-dashed hover:bg-gray-200">
        Gi tilbakemelding
        <ThumbUp />
      </button>
    </>
  )
}

type ChatBubbleProps = {
  children: React.ReactNode
  color: 'neutral' | 'fancy'
}
const ChatBubble = (p: ChatBubbleProps) => {
  const bubbleClasses = {
    // User message must pad to the right, and have a chat bubble arrow on the right
    neutral: 'bg-gray-300 text-black ml-auto rounded-r-lg pr-4',
    fancy: `mr-auto fancy-pink-background`
  }
  return (
    <div className={`font-poppins prose lg:prose prose-stone p-3 rounded-lg ${bubbleClasses[p.color]}`}>
      {p.children}
    </div>
  )
}

const ChatInputBoxAndSubmitButton = (props: {
  onSubmit: (message: string) => void
  canSubmit: boolean
}) => {
  const [input, setInput] = useState('')
  const canSubmit = props.canSubmit && input.trim().length > 0

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault() // Prevent default form submission
    if (canSubmit) {
      props.onSubmit(input)
      setInput('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevent the default action to avoid inserting a newline
      handleSubmit(event as unknown as React.FormEvent) // Cast the event type to match handleSubmit
    }
  }

  return (
    <form className="flex space-x-2 max-w-5xl m-auto" onSubmit={handleSubmit}>
      <textarea
        placeholder="Skriv en melding her..."
        className="flex-1 border-2 border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md focus:bg-white focus:text-black"
        rows={3}
        value={input}
        onChange={e => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button type="submit"
              className={`text-white font-bold py-2 px-4 rounded bg-blue-900 ${canSubmit ? 'hover:bg-blue-800' : 'bg-gray-300'}`}
              disabled={!canSubmit}>
        Send
      </button>
    </form>
  )
}


export default App
