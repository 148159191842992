import demo from './demo'
import dev from './dev'
import rusinfo from './rusinfo'

const configs = {
  demo,
  rusinfo,
  dev,
}

export default configs
