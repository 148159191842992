import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '1349667d-9ca0-45d9-a38c-b2a088c39fc8',
  header: 'RUSinfo',
  subheader: 'Nyere prototype',
  // logo: 'drugchat.jpg',
  logo: 'semantic_lab_logo_hvit.png',
  theme: {
    header: 'bg-blue-900',
    headerText: 'text-white',
    bubbles: 'bg-green-600'
  },
  // chatServer: 'http://localhost:8080/api/v1/chatbots/dev'
  chatServer: 'https://ai.semanticlab.no/api/v1/chatbots/dev'
}
export default config
